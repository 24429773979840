import React from "react";
import '../../App.scss';
import '../../index.scss';
import './PersonList.scss';
import PersonList from "./PersonList";
import {useParams} from "react-router-dom";

function PersonListByFuneralHome() {
    const params = useParams();
    return (
        <PersonList funeralHomeAlias={params.alias} clearFilter={true} overrideBlockCalls={true}/>
    );
}

export default PersonListByFuneralHome;
