export const TranslationHelper = {
  getWebsiteBasedOnLocale: function (object, i18n) {
    if (object == null) return "";
    return i18n?.language?.toLowerCase() === "fr" && !!object.WEBSITE_FRENCH
      ? `https://${object.WEBSITE_FRENCH}`
      : `https://${object.WEBSITE_DUTCH}`;
  },

  getValueBasedOnLocale: function (object, i18n) {
    if (object == null) return "";
    return i18n?.language?.toLowerCase() === "fr" && !!object.FRENCH
      ? object.FRENCH
      : object.DUTCH;
  },
  getCityName(object, i18n) {
    if (object == null) return "";
    return i18n?.language?.toLowerCase() === "fr" && !!object.nameFr
      ? object.nameFr
      : object.name;
  },
};
