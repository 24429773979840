import React, {Fragment} from 'react'
import Col from "react-bootstrap/Col";
import noPictureCondoleance from "../../assets/images/Template No Picture Condoleances.png";
import {Trans, useTranslation} from "react-i18next";
import {Show} from "../Util/Show";
import "./PersonInfo.scss"
import {DateHelper} from "../../helper/DateHelper";

const PersonInfo = ({dossierData}) => {
    const {t, i18n} = useTranslation();

    const title = dossierData.deceased.TITLE;
    let suffix;
    switch (title) {
      case "0":
        suffix = '.general';
        break;
      case "1":
        suffix = '.male';
        break;
      case "2":
        suffix = '.female';
        break;
      default:
        suffix = '.general'; // Fallback if TITLE is not 0, 1, or 2
    }
    const birth_and_death_gender = `person_detail.person_birth_and_death_place_and_date${suffix}`;

    return (
        <Fragment>
                <Col md={3}>
                    <div>
                        {dossierData.deceasedphoto ?
                            <img src={dossierData.deceasedphoto} alt="personImage"
                                 className="image-shape"/>
                            :
                            <img src={noPictureCondoleance}
                                 style={{width: "275px"}}
                                 alt="condoleanceImage"
                                 className="image-shape"/>}
                    </div>
                </Col>
                <Col md={5} className='align-middle'>
                    <div style={{padding:'0 30px'}}>
                        <h1 className="person-detail-name mb-5 font-style">{dossierData.deceased_full_name}</h1>
                        {/* Custom aanvraag voor uitvaart van gezin die overleden is in auto-ongeluk. 
                            Ze willen 1 pagina voor de 3 overlijdens en dus zonder geboorte / overlijdensdatum */}
                        <Show>
                            <Show.When isTrue={dossierData.dossier_number !== "38984"}>
                                <h5 className="sh-person-info">
                                    <Trans t={t} i18nKey={birth_and_death_gender} values={{
                                        firstname: (!!dossierData.deceased.CALLINGNAME ? dossierData.deceased.CALLINGNAME : dossierData.deceased_first_name),
                                        place_of_birth: dossierData.deceased_place_of_birth,
                                        date_of_birth: DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language),
                                        place_of_death: dossierData.deceased_city,
                                        date_of_death: DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)
                                    }}/>
                                </h5>

                                <h5 className="remove-margin sh-display">
                                    <p>{dossierData.deceased_place_of_birth}, {DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language)} <br/> {dossierData.deceased_city}, {DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)}
                                    </p>
                                </h5>
                            </Show.When>
                        </Show>

                        <Show>
                            <Show.When isTrue={!!dossierData.funeral.MYSERENIREMARKS}>
                                <div className={"mt-5 my-sereni-text"}
                                     dangerouslySetInnerHTML={{__html: dossierData.funeral.MYSERENIREMARKS}}></div>
                            </Show.When>
                        </Show>
                    </div>
                </Col>
        </Fragment>
    )
}
export default PersonInfo
