import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React, {Fragment, useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "../../App.scss"
import "./SubHeader.scss"
import colors from "../../scss/theme-colors.module.scss"
import Select from "react-select";
import citiesJsonData from './citiesBelgium.json'
import {Trans, useTranslation} from "react-i18next";
import {useWindowDimensions} from "../../helper/useWindowDimensions";
import {TranslationHelper} from "../../helper/TranslationHelper";

function SubHeader({filterHandler, personListHandler, getPersonListFilter, funeralHomeBranchList}) {
    const {t, i18n} = useTranslation();
    const currentYear = new Date().getFullYear();
    const [cities] = useState(citiesJsonData);

    const windowDimensions = useWindowDimensions();

    const birthRange = useMemo(() => {
        const size = 106;
        const startAt = currentYear - size + 1
        return [...Array(size).keys()].map(i => ({label: i + startAt, value: i + startAt}));
    }, [currentYear]);

    const selectTheme = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: 'lightgray',
            primary: colors.primary,
        },
    });

    const selectStyle = {
        control: (baseStyles, state) => {
            return ({
                ...baseStyles,
                borderColor: 'white',
                borderRadius: '10px',
                padding: '0 !important'
            })
        },
    };

    const selectComponent = {DropdownIndicator: () => null, IndicatorSeparator: () => null};

    function birthPlaceChange(e) {
        const list = !!e?.searchList ? e.searchList : []
        personListHandler(prev => ({
            ...prev,
            birthPlaces: {searchList:list , postalCode:e?.postalCode},
            selectedPage: 1
        }));
    }

    function placeOfDeathChange(e) {
        const list = !!e?.searchList ? e.searchList : []
        personListHandler(prev => ({
            ...prev,
            placeOfDeath: {searchList:list , postalCode:e?.postalCode, name: e?.name, nameFr: e?.nameFr, subCity: e?.subCity},
            selectedPage: 1
        }));
    }

    return (
        <Fragment>
            <Container>
                {!windowDimensions.isLarge &&
                    <Row className="mx-4 my-3 ps-3 py-2 align-items-center">
                        <Col md={6}>
                            <div className="searchbox-wrapper">
                                <div className="searchbox">
                                    <input
                                        type="text"
                                        id="search"
                                        className="input"
                                        value={getPersonListFilter.search}
                                        onChange={(e) => {
                                            filterHandler(e.target.value);
                                        }}
                                    />
                                    <Button className="rounded-pill h-75 searchbtn" variant="dark">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    }
                <Row className="mx-4 my-3 ps-3 py-2 justify-content-md-center align-items-center">
                    {windowDimensions.isLarge &&
                        <Col sm={3} className="border-end border-dark hide-border">
                            <div className="searchbox-wrapper">
                                <div className="searchbox">
                                    <input
                                        type="text"
                                        id="search"
                                        className="input"
                                        value={getPersonListFilter.search}
                                        onChange={(e) => {
                                            filterHandler(e.target.value);
                                        }}
                                    />
                                    <Button className="rounded-pill h-75 searchbtn" variant="dark">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    }

                    {/*GeboortePlaats*/}
                    <Col className="border-end border-dark mobile-display-hide ">
                        <div>
                            <b><Trans t={t} i18nKey={'common.born_in'}/></b>
                            <Select
                                value={cities.filter(city => city.postalCode === getPersonListFilter.birthPlaces.postalCode)}
                                options={cities}
                                placeholder={<Trans t={t} i18nKey={'common.place_of_brith'}/>}
                                isClearable={true}
                                classNamePrefix="react-select"
                                components={selectComponent}
                                styles={selectStyle}
                                theme={selectTheme}
                                getOptionLabel={(option) => TranslationHelper.getCityName(option, i18n)} // TODO @Siebe translation
                                getOptionValue={(option) => option}
                                onChange={birthPlaceChange}
                            />
                        </div>
                    </Col>
                    {/*OverlijdenPlaats*/}
                    <Col className="border-end border-dark">
                        <div>
                            <b><Trans t={t} i18nKey={'common.died_in'}/></b> <br/>
                            <Select
                                value={cities.filter(city => `${city.postalCode} - ${city.name}` === `${getPersonListFilter.placeOfDeath.postalCode} - ${getPersonListFilter.placeOfDeath.name}`)}
                                options={cities}
                                placeholder={<Trans t={t} i18nKey={'common.place_of_death'}/>}
                                isClearable={true}
                                classNamePrefix="react-select"
                                components={selectComponent}
                                styles={selectStyle}
                                theme={selectTheme}
                                getOptionLabel={(option) => `${option.postalCode} - ${TranslationHelper.getCityName(option, i18n)}`} // TODO @Siebe translation
                                getOptionValue={(option) => `${option.postalCode} - ${option.name}`}
                                onChange={placeOfDeathChange}
                            />
                        </div>
                    </Col>
                    {/*UitvaartVestiging*/}
                    <Col className="border-end border-dark hide-border">
                        <div>
                            <b> <Trans t={t} i18nKey={'common.branch'}/></b> <br/>
                            <Select
                                value={funeralHomeBranchList.filter(
                                    item => item.FUNERALHOMEID === getPersonListFilter.funeralHome
                                )}
                                options={funeralHomeBranchList}
                                placeholder={<Trans t={t} i18nKey={'common.funeral_branch'}/>}
                                isClearable={true}
                                classNamePrefix="react-select"
                                components={selectComponent}
                                styles={selectStyle}
                                theme={selectTheme}
                                getOptionLabel={(option) => option.NAME}
                                getOptionValue={(option) => option.FUNERALHOMEID}
                                onChange={(e) => personListHandler(prev => ({
                                    ...prev,
                                    funeralHome: e?.FUNERALHOMEID,
                                    selectedPage: 1
                                }))}
                            />
                        </div>
                    </Col>
                    {/*GeboorteJaar*/}
                    <Col className="mobile-display-hide ">
                        <div>
                            <b><Trans t={t} i18nKey={'common.born_on'}/></b> <br/>
                            <Select
                                value={
                                    birthRange.filter(item =>
                                        item.label === getPersonListFilter.birthYear
                                    )
                                }
                                options={birthRange}
                                placeholder={<Trans t={t} i18nKey={'common.year_of_brith'}/>}
                                isClearable={true}
                                classNamePrefix="react-select"
                                components={selectComponent}
                                styles={selectStyle}
                                theme={selectTheme}
                                onChange={(e) => personListHandler(prev => ({
                                    ...prev,
                                    birthYear: e?.value,
                                    selectedPage: 1
                                }))}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr/>
            </Container>
        </Fragment>
    );
}

export default SubHeader;
