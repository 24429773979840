import React, {Fragment, useEffect, useState} from 'react'
import Row from "react-bootstrap/Row";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import FuneralFlowerForm from "./FuneralFlowerForm";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {PAGES} from "../../Pages";
import {Trans, useTranslation} from "react-i18next";
import {CondolenceApi} from "../../api/CondelenceApi";
import {showFlowers} from "../RandomGrid/useServiceGridItems";
import {Spinner} from "react-bootstrap";
import "../PersonDetail/PersonInfo.scss"
import PersonInfo from "../PersonDetail/PersonInfo";
import "./FuneralFlower.scss"
import {BackButton} from "../BackButton/BackButton";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import {Show} from "../Util/Show";

const FuneralFlower = () => {
    const {t, i18n} = useTranslation();
    const [dossierData, setDossierData] = useState();
    const {name} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [flowerImage, setFlowerImage] = useState('bloemstuk.jpg')
    const location = useLocation();
    const [flowerNameList, setFlowerNameList] = useState([]);
    const [flowerPrice, setFlowerPrice] = useState({steps: []});

    useEffect(() => {
        if (!!name) {
            setLoading(true);
            CondolenceApi.getDossierByAlias(name)
                .then(res => {
                    if (showFlowers(res)) {
                        setDossierData(res);
                        setLoading(false);
                        setFlowerNameList(res.flowers.map(
                            flower => ({
                                label: t(`flowers.labels.${flower.id}`),
                                value: flower.id,
                                steps: flower.steps.map(price => ({
                                    label: price,
                                    value: price
                                })),
                                imageName: flower.image
                            })
                        ));
                    } else {
                        navigate(PAGES.PersonDetail.replace(":name", name))
                    }
                })
                .catch(_ => toast.error(t('error.general'),{position: "top-center"}))
        }
    }, [name, navigate,t]);

    useEffect(() => {
        if (!!location && !!location.state.flowerid && !!flowerNameList) {
            const selectedFlower = flowerNameList.filter(f => f.value === location.state.flowerid);
            selectedFlower.forEach(flower => {
                setFlowerImage(flower.imageName);
                setFlowerPrice(flower)
            })
        }
    }, [location, flowerNameList]);


    return (
        <Fragment>
            <Container>
                <Row className={"mb-5"}>
                    <BackButton path={generatePath(PAGES.CondolenceFlowers, {name: name})} textComponent={<Trans t={t} i18nKey={"common.overview"}/>}/>
                </Row>
                {loading === true && (
                    <Row className={"justify-content-center mb-4"}>
                        <Spinner animation="border"/>
                    </Row>
                )}
                {!!dossierData && (
                    <Fragment>
                        <Row className="justify-content-center mb-5">
                            <Col className="display-person-info">
                                <Row className="align-middle text-center text-md-start">
                                    <PersonInfo dossierData={dossierData}/>
                                </Row>
                            </Col>
                            <Col sm={10} lg={5}>
                                <h2 className="align-middle">{flowerPrice.label}</h2>
                                <h1 className="mobile-display-hide font-domain">{dossierData.deceased_full_name}</h1>
                                <Show>
                                    <Show.When isTrue={dossierData.dossier_number !== "38984"}>
                                        <span className="m-1 h5 mobile-display-hide">{DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language)}  &nbsp;|&nbsp;</span>
                                        <span className="m-1 h5 mobile-display-hide">{DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)}
                                        </span>
                                    </Show.When>
                                </Show>
                                <div className="mobile-display-hide">
                                    <img src={require(`../../assets/flowerImage/${flowerImage}`)} height="360"
                                         width="420" className="p-1" alt="flower"/>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <FuneralFlowerForm flowerPrice={flowerPrice}/>
                            </Col>
                        </Row>
                    </Fragment>
                )}

                <hr/>
                <Row className="justify-content-md-center mobile-display-hide">
                    <Col md={4} className={"my-4"}>
                        <h3><Trans t={t} i18nKey={'funeral_flower.flexible_payment'}/></h3>
                        <p>
                            <Trans t={t} i18nKey={'funeral_flower.flexible_payment_text'}/>
                        </p>
                    </Col>
                    <Col md={4} className={"my-4"}>
                        <h3><Trans t={t} i18nKey={'funeral_flower.a_perfect_delivery'}/></h3>
                        <p><Trans t={t} i18nKey={'funeral_flower.a_perfect_delivery_text'}/></p>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default FuneralFlower
