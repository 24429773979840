import React from "react";
import {Container} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import "../../App.scss";
import "../../scss/theme-colors.module.scss"
import {LanguageDropDown} from "./LanguageDropDown";
import {Trans, useTranslation} from "react-i18next";


function Header() {
    const {t} = useTranslation();

    return (
        <Navbar>
            <Container>
                <Navbar.Brand className={'navbar-brand font-domain'} href="/"><Trans t={t} i18nKey={"common.header"}/></Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    <LanguageDropDown/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
