import {useEffect, useState} from 'react';

export const useFilterItemState = (key = 'keyFilter', defaultValue, clear) => {
    if (clear) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
    }
    const storedValue = JSON.parse(window.sessionStorage.getItem(key));
    const initial = storedValue ? storedValue : defaultValue;
    const [filterStatus, setFilterStatus] = useState(initial);

    useEffect(() => {
        if (!!filterStatus) {
            window.sessionStorage.setItem(key, JSON.stringify(filterStatus));
        }
        return () => {
            if (!!filterStatus) {
                window.sessionStorage.setItem(key, JSON.stringify(filterStatus));
            }
        }
    });

    return [filterStatus, setFilterStatus];
}
