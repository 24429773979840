import {Accordion, ListGroup} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import React, {Fragment} from "react";
import './PublicCondolences.scss'
import {DateHelper} from "../../helper/DateHelper";

export function PublicCondolences({condolences, canShowPublicCondolences, ...className}) {
    const {t, i18n} = useTranslation();

    if (!condolences || condolences.length === 0 || !canShowPublicCondolences || condolences.find(c => c.is_public === 1) === undefined) {
        return <></>
    }

    function mapCondolence(c, index) {
        return c.is_public !== 1 ? <Fragment></Fragment> : (<ListGroup.Item>
            <div className={"my-1"}>
                <div className="fw-bold">{c.name}</div>
                <div className={"fw-light time-ago"}>{DateHelper.timeAgo(c.created_at, i18n.language)}</div>
                {c.message}
            </div>
        </ListGroup.Item>);
    }

    return (
        <Accordion {...className} bsPrefix={"public-condolences-accordion"}>
            <Accordion.Item eventKey="0" style={{
                borderWidth: '0 0 1px 0',
                borderRadius: "0 !important",
            }}>
                <Accordion.Header>
                    <h5>
                        <Trans t={t} i18nKey={'condolence.title'}/>
                    </h5>
                </Accordion.Header>
                <Accordion.Body style={{fontSize: '13px'}}>
                    <ListGroup className={'publicCondolenceList'}>
                        {condolences.map((c,i) => <Fragment key={i}>{mapCondolence(c)}</Fragment> )}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}