import React, {Fragment, useEffect, useMemo, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Dropdown, Spinner} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {CondolenceApi} from "../../api/CondelenceApi";
import {DateHelper} from "../../helper/DateHelper";
import {useNavigate} from "react-router-dom";
import SubHeader from "./SubHeader";
import {useDebouncedCallback} from "use-debounce";
import noPictureCondoleance from '../../assets/images/Template No Picture Condoleances.png';
import '../../App.scss';
import '../../index.scss';
import './PersonList.scss';
import {Pagination} from "../Pagination/Pagination";
import {Each} from "../Util/Each";
import {Show} from "../Util/Show";
import {PAGES} from "../../Pages";
import {useFilterItemState} from "./useFilterItemState";
import {useWindowDimensions} from "../../helper/useWindowDimensions";
import classnames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {toast} from "react-toastify";

const pageAmountOptions = [9, 15, 27]

function PersonList({funeralHomeAlias = undefined, clearFilter = false, overrideBlockCalls = false}) {
    const navigate = useNavigate();
    const [blockCalls, setBlockCalls] = useState(overrideBlockCalls)

    const [funeralHomeBranchList, setFuneralHomeBranchList] = useState([]);
    const [filter, setFilter] = useFilterItemState('mainFilter', {
        funeralHome: undefined,
        placeOfDeath: {searchList: [], postalCode: undefined},
        birthPlaces: {searchList: [], postalCode: undefined},
        birthYear: undefined,
        search: '',
        selectedPage: 1,
        totalAmount: 9
    }, clearFilter);

    const {t} = useTranslation();

    const debounced = useDebouncedCallback((value) => setFilter(prev => ({
        ...prev,
        search: value,
        selectedPage: 1
    })));

    const [loading, setLoading] = useState(false);
    const [condolences, setCondolences] = useState({groupedData: [], total: 0});

    const amountOfPages = useMemo(() => {
        return Math.ceil(condolences.total / filter.totalAmount);
    }, [condolences, filter]);

    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        setLoading(true);
        CondolenceApi.getAllFuneralHomeBranch().then((res) => {
            setFuneralHomeBranchList(res);
            if (!!funeralHomeAlias) {
                const funeralHome = res.filter(f => f.ALIAS === funeralHomeAlias)[0];
                if (!!funeralHome) {
                    setFilter(prev => ({...prev, funeralHome: funeralHome.FUNERALHOMEID}))
                }
            }
            setLoading(false);
            setBlockCalls(false);
        })
            .catch(_ => toast.error(t('error.general'), {position: "top-center"}))
    }, [t, funeralHomeAlias, setFilter]);

    useEffect(() => {
        if (!blockCalls) {
            setLoading(true);
            CondolenceApi.getAllByFuneralHome({
                amount: filter.totalAmount,
                page: filter.selectedPage
            }, filter.search, filter.birthYear, filter.deathYear, filter.funeralHome, filter.birthPlaces.searchList, !!filter.placeOfDeath?.postalCode ? [filter.placeOfDeath.postalCode] : [])
                .then((response) => {
                    let result = {
                        groupedData: response.data.reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r, []),
                        total: response.meta.total
                    }
                    setCondolences(result);
                    setLoading(false);
                })
                .catch((_) => {
                    setLoading(false)
                    toast.error(t('error.general'), {position: "top-center"});
                });
        }
    }, [blockCalls, filter, t]);

    function filterHandler(f) {
        debounced(f);
    }

    function changePageSize(amount) {
        setFilter(prevState => ({
            ...prevState,
            totalAmount: amount,
            selectedPage: 1
        }));
    }

    function changePage(newPage) {
        if (newPage > 0 && newPage <= amountOfPages) {
            setFilter(prevState => ({
                ...prevState,
                selectedPage: newPage
            }));
        }
    }

    function getColumnClassName(index, groupLength) {
        const names = ["my-3", "px-3"]
        if ((!windowDimensions.isSmall) && (index !== 2 || (index + 1) !== groupLength)) {
            names.push("border-end", "border-dark")
        }
        return classnames(names)
    }

    return (
        <Fragment>
            <SubHeader filterHandler={filterHandler}
                       getPersonListFilter={filter}
                       personListHandler={setFilter}
                       funeralHomeBranchList={funeralHomeBranchList}
            />
            {!windowDimensions.isSmall &&
                <Container>
                    <Row className="mx-2 mt-4 mb-4 justify-content-md-center">
                        <Col sm={2} className="mobile-display-hide">
                            {/*{condolences.total} <Trans t={t} i18nKey={'common.results'}/>*/}
                        </Col>
                        <Col sm={8} className="justify-content-center">
                            <Pagination currentPage={filter.selectedPage}
                                        totalCount={condolences.total}
                                        pageSize={filter.totalAmount}
                                        onPageChange={changePage}
                            />
                        </Col>
                        <Col sm={2} className="mobile-display-hide">
                            <div className={"d-flex justify-content-end"}>
                                <span className={"me-2"}><Trans t={t} i18nKey={'common.show'}/></span>
                                <Dropdown>
                                    <Dropdown.Toggle as={"div"} className="rounded-pill float-end page-amount"
                                                     id="dropdown-basic">
                                        {filter.totalAmount}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Each of={pageAmountOptions} render={(item) =>
                                            <Dropdown.Item key={item}
                                                           onClick={() => changePageSize(item)}>{item}</Dropdown.Item>
                                        }/>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            <Container className={"mb-5"}>
                <Show>
                    <Show.When isTrue={loading}>
                        <Row className={"justify-content-center mb-4"}>
                            <Spinner animation="border"/>
                        </Row>
                    </Show.When>
                    <Show.Else>
                        <Each of={condolences.groupedData} render={(group) => (
                            <Row className={"justify-content-center my-2"}>
                                <Each of={group} render={(condolence, index) => (
                                    <Col md={"auto"} className={getColumnClassName(index, group.length)}>
                                        <div className={"d-flex"}>
                                            <Show>
                                                <Show.When isTrue={!!condolence.deceasedphoto}>
                                                    <img
                                                        src={condolence.deceasedphoto}
                                                        className={"person-img"}
                                                        alt="personImage"
                                                        onClick={() => {
                                                            navigate(PAGES.PersonDetail.replace(":name", condolence.alias), {state: {data: condolence}});
                                                        }}/>
                                                </Show.When>
                                                <Show.Else>
                                                    <img src={noPictureCondoleance}
                                                         className={"person-img"}
                                                         alt="condoleanceImage"
                                                         cursor='pointer'
                                                         onClick={() => {
                                                             navigate(PAGES.PersonDetail.replace(":name", condolence.alias), {state: {data: condolence}});
                                                         }}
                                                    />
                                                </Show.Else>
                                            </Show>
                                            <div>
                                        <span className='font-style h3 m-2 person-name'
                                              onClick={() => {
                                                  navigate(PAGES.PersonDetail.replace(":name", condolence.alias), {state: {data: condolence}});
                                              }}>
                                                {condolence.deceased_full_name}
                                            </span>
                                                <br/>
                                                <Show>
                                                    <Show.When isTrue={condolence.alias.toLowerCase() !== "andriesderaeve"}>
                                                        <div className='align-middle mx-2 dateNoWrap'>
                                                            <span
                                                                className={"dateNoWrap"}>{DateHelper.formatDate(condolence.date_of_birth)}</span> &nbsp; | &nbsp;
                                                            <span
                                                                className={"dateNoWrap"}>{DateHelper.formatDate(condolence.date_of_death, undefined, "yyyy-MM-DD")}</span>
                                                        </div>
                                                    </Show.When>
                                                </Show>
                                            </div>
                                        </div>
                                    </Col>
                                )}/>
                            </Row>
                        )}/>
                    </Show.Else>
                </Show>
            </Container>
            {windowDimensions.isSmall &&
                <Container className={"sticky-bottom bg-white"}>
                    <hr/>
                    <Row className="mx-auto justify-content-md-center">
                        <Col sm={12} className="mb-3 justify-content-center">
                            <Pagination currentPage={filter.selectedPage}
                                        totalCount={condolences.total}
                                        pageSize={filter.totalAmount}
                                        onPageChange={changePage}
                                        siblingCount={1}
                            />
                        </Col>
                    </Row>
                </Container>
            }
        </Fragment>
    );
}

export default PersonList;
