import cookie from 'js-cookie';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {NavDropdown} from "react-bootstrap";
import {Each} from "../Util/Each";

export function LanguageDropDown() {
    const lngs = {
        nl: {nativeName: 'nl'},
        fr: {nativeName: 'fr'},
    }

    const {i18n} = useTranslation();
    const currentLanguageCode = cookie.get('i18next') || i18n.language;
    const [language, setLanguage] = useState(lngs[currentLanguageCode].nativeName);

    function handleLanguageChange(lng) {
        setLanguage(lngs[lng].nativeName);
        i18n.changeLanguage(lng).then(r => {
            const e = new CustomEvent("changeLanguage", {'detail': {'language': lng}});
            document.dispatchEvent(e);
        });
    }

    return (
        <NavDropdown
            className="dropdown-fit-content remove-lng-dp"
            title={language}
        >
            <Each of={Object.keys(lngs)} render={(lng) => (
                <NavDropdown.Item onClick={() => handleLanguageChange(lng)}>
                    <span>{lngs[lng].nativeName}</span>
                </NavDropdown.Item>

            )}/>
        </NavDropdown>
    );
}
