import React, {Fragment} from "react";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons/faCircleArrowRight";
import {Link} from "react-router-dom";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import classnames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Card-Colors.scss'
import {isSpecialUrl} from "../RandomGrid/useServiceGridItems";


function CondolenceCard({gridElements, dossierNumber, isCondolencePublic}) {
    const specialURl = isSpecialUrl();

    const elements = gridElements.elementsExceptCondolence;
    if (elements.length === 0) {
        return <Fragment/>
    }

    function getLinkTarget(data) {
        if (data.urlIsExternal === true) {
            return ({target: "_blank"});
        }
        return {};
    }

    function getCard(element, index) {
        let card = (
            <Card className={classnames("my-2", "border-0", "h-100", {
                'bg-opacity-50': !element.active,
            }, element.bg ? element.bg : 'bg-light')}>
                {!specialURl &&
                    <Card.Title className={classnames("m-2", "p-2", {'text-black-50': !element.active})}>{element.title}
                        <FontAwesomeIcon className="float-end"
                                         icon={element.active ? faCircleArrowRight : faCircleXmark}
                        />
                    </Card.Title>
                }
                <Card.Body className={classnames({
                    'text-black-50': !element.active,
                    'my-2': specialURl,
                    'd-flex': specialURl,
                    'justify-content-center': specialURl
                },)}>
                    <div>
                        <div>
                            {element.active && element.subText}
                            {!element.active && element.subTextDisabled}
                        </div>
                        {specialURl &&
                            <div style={{justifySelf: "center"}}>
                                <FontAwesomeIcon
                                    icon={element.active ? faCircleArrowRight : faCircleXmark}
                                />
                            </div>
                        }
                    </div>
                </Card.Body>
            </Card>);

        if (!!element.url && element.active) {
            card = (<Link className={"anchor-no-decoration"} to={element.url} {...getLinkTarget(element)}>
                {card}
            </Link>);
        } else if (!!element.onClickHandler && element.active) {
            card = (<div className={"h-100 clickable"} onClick={() => {element.onClickHandler()}}>{card}</div>)
        }

        return (<Fragment key={index}>
            <Col className={"my-2"} lg={specialURl ? 12 : 4}>
                {card}
            </Col>
        </Fragment>);
    }


    return (
        <div>
            <Row className="m-2">
                {gridElements.elementsExceptCondolence.map((element, i) => getCard(element, i))}
            </Row>
        </div>
    )
}

export default CondolenceCard
