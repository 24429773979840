import React, {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {generatePath, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import CondoleanceFlower from "../../assets/images/condoleance-flower.png";
import {CondolenceApi} from "../../api/CondelenceApi";
import {showFlowers} from "../RandomGrid/useServiceGridItems";
import {Spinner} from "react-bootstrap";
import {PAGES} from "../../Pages";
import {Trans, useTranslation} from "react-i18next";
import classnames from "classnames";
import './CondolenceFlowers.scss'
import {BackButton} from "../BackButton/BackButton";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import {Show} from "../Util/Show";

function CondolenceFlowers() {
    const {t, i18n} = useTranslation();
    const [dossierData, setDossierData] = useState();
    const [loading, setLoading] = useState(false);
    const {name} = useParams();
    const navigate = useNavigate();
    const [flowerNameList, setFlowerNameList] = useState();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!!name) {
            setLoading(true);
            CondolenceApi.getDossierByAlias(name)
                .then(res => {
                    if (showFlowers(res)) {
                        setDossierData(res);
                        setFlowerNameList(res.flowers.map(
                            flower => ({
                                label: t(`flowers.labels.${flower.id}`),
                                value: flower.id,
                                imageName: flower.image,
                                steps: flower.steps
                            })
                        ));
                        setLoading(false);
                    } else {
                        navigate(PAGES.PersonDetail.replace(":name", name))
                    }
                })
                .catch(_ => toast.error(t('error.general'),{position: "top-center"}))
        }
    }, [name, navigate, t]);

    useEffect(() => {
        if (searchParams.has('hash')) {
            const hash = searchParams.get("hash");
            searchParams.delete('hash');
            CondolenceApi.getFlowerPaymentStatus(hash)
                .then((res) => {
                    if (res.status === 'paid') {
                        toast.success(t('flowers.order_succes'),{position: "top-center"});
                    } else {
                        toast.warn(t('flowers.order_error'),{position: "top-center"});
                    }
                })
                .catch(_ => toast.error(t('error.general'),{position: "top-center"}));
        }
    }, [searchParams, t]);


    function onSelectFlowerButton() {
        return (
            flowerNameList.map((item, index) => (
                <Col md={2}
                     className={classnames((flowerNameList.length !== index + 1) ? 'border-end border-dark text-center' : 'text-center')}
                     key={item.index}>
                    <h5>{item.label}</h5>
                    <p style={{fontSize:"13px"}}>{`€${Math.min(...item.steps)} - €${Math.max(...item.steps)}`}</p>
                    <Button style={{fontSize: "13px"}} variant="dark" onClick={() => navigate(generatePath(PAGES.FuneralFlower, {name: name}), {
                        state: {
                            flowerid: item.value,
                            imageName: item.imageName
                        }
                    })}>
                        <Trans t={t} i18nKey={'common.purchase'}/>
                    </Button>
                </Col>
            ))
        );
    }

    return (
        <Container>
            <Row>
                <BackButton path={generatePath(PAGES.PersonDetail, {name: name})} textComponent={<Trans t={t} i18nKey={"common.back"}/>}/>
            </Row>
            {loading === true && (
                <Row className={"justify-content-center mb-4"}>
                    <Spinner animation="border"/>
                </Row>
            )}
            {!!dossierData && <Fragment>
                <Row className="mx-2 justify-content-md-center">
                    <Col md={4} lg={3}>
                        <img src={CondoleanceFlower} className="p-1 opacity-50 flower-header-image" alt="flower"/>
                    </Col>
                    <Col md={4} lg={3} className={"align-self-center blend-mode-darken"}>
                        <h2 className='font-'>
                            <Trans t={t} i18nKey={'flowers.a_beautiful_souvenir_from_nature'}/>
                        </h2>
                        <p style={{fontSize: "13px"}}>
                            <Trans t={t} i18nKey={'flowers.a_beautiful_souvenir_from_nature_text'}/>
                        </p>
                    </Col>
                </Row>
                <Row className="mx-2 m-4 p-4 justify-content-md-center">
                    <Col md={3}>
                        <p className="m-2 p-3 px-4 border border-dark" style={{fontSize:"13px"}}>
                            <Trans t={t} i18nKey={'flowers.order_note_text'}/></p>
                    </Col>
                    <Col md={4} className="align-self-center mb-2 pb-2">
                        <h1 className={"font-domain"}>{dossierData.deceased_full_name}</h1>
                        <Show>
                            <Show.When isTrue={dossierData.dossier_number !== "38984"}>
                                <span className="m-1 h5">{DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language)}
                                    </span>
                                &nbsp;|&nbsp;
                                <span className="m-1 h5">{DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)}
                                    </span>
                            </Show.When>
                        </Show>
                    </Col>
                </Row>
                <hr/>
                <Row className="m-4 mx-2 p-4 justify-content-md-center">
                    <Col md={8}>
                        <h3><Trans t={t} i18nKey={'flowers.choose_from_our_range'}/></h3>
                        <p style={{fontSize:'13px'}}>
                            <Trans t={t} i18nKey={'flowers.choose_from_our_range_text'}/>
                        </p>
                    </Col>
                </Row>
                <Row className="m-2 p-2 justify-content-md-center">
                    {onSelectFlowerButton(dossierData)}
                </Row>
                <Row className="m-4 mx-2 mt-4 pt-4 px-2 justify-content-md-center">
                    <Col md={8}>
                        <h3><Trans t={t} i18nKey={'flowers.how_does_a_sereni_florist_work'}/></h3>
                    </Col>
                </Row>
                <Row className="m-2 mx-2 p-2 justify-content-md-center how-it-works">
                    <Col md={4}>
                        <h6 style={{fontWeight:'bold'}}><Trans t={t} i18nKey={'flowers.local_culture'}/></h6>
                        <p>
                            <Trans t={t} i18nKey={'flowers.local_culture_text'}/>
                        </p>
                        <h6><Trans t={t} i18nKey={'flowers.seasons_come_and_go'}/></h6>
                        <p><Trans t={t} i18nKey={'flowers.seasons_come_and_go_text'}/></p>
                    </Col>
                    <Col md={4}>
                        <h6><Trans t={t} i18nKey={'flowers.until_when_you_can_order'}/></h6>
                        <p><Trans t={t} i18nKey={'flowers.until_when_you_can_order_text'}/></p>
                        <h6><Trans t={t} i18nKey={'flowers.delivery'}/></h6>
                        <p><Trans t={t} i18nKey={'flowers.delivery_text'}/></p>
                    </Col>
                </Row>
            </Fragment>}
        </Container>
    );
}

export default CondolenceFlowers;
