const CardFolder = require.context("../../assets/images/cards", true);

export const useCards = () => {
    const cardsMap = new Map();
    CardFolder.keys().map(image => ({
        type: image.split("/")[1],
        file: CardFolder(image)
    })).forEach(i => cardsMap.has(i.type) ? cardsMap.get(i.type).push(i.file) : cardsMap.set(i.type, [i.file]));
    return {
        cardsMap,
        amount: [...cardsMap.keys()].length
    }
}