import moment from "moment/moment";
import 'moment/locale/nl';
import 'moment/locale/fr';

import nl from 'javascript-time-ago/locale/nl'
import fr from 'javascript-time-ago/locale/fr'
import TimeAgo from "javascript-time-ago";

TimeAgo.addDefaultLocale(nl);
TimeAgo.addLocale(fr);


export const DateHelper = {
    formatDate: function (date, format = "DD-MM-yyyy", inputFormat = "yyyy-MM-DD HH:mm:ss Z") {
        return moment(date, inputFormat).format(format);
    },

    formatDateWithLocale: function (date, format, locale, inputFormat = "yyyy-MM-DD HH:mm:ss Z") {
        return moment(date, inputFormat).locale(locale).format(format);
    },

    toDate: function(date, inputFormat = "yyyy-MM-DD HH:mm:ss Z") {
        return moment(date, inputFormat).toDate();
    },
    timeAgo: function (date, i18N, inputFormat = "yyyy-MM-DD HH:mm:ss Z") {
        return new TimeAgo(i18N).format(moment(date, inputFormat).toDate(), 'round-minute')
    }
}