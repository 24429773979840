import React, {Fragment} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import DefaultLogo from "../../assets/images/icon.png";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import "./Footer.scss"

function Footer() {
    const {t} = useTranslation();
    return (
        <Fragment>
            <Container className="footer mobile-footer">
                <hr/>
                <Row className={"my-5"}>
                    <Col md={2}>
                        <img style={{width: "25px"}} src={DefaultLogo} alt={"sereni logo"}></img>
                    </Col>
                    <Col className={"mx-3"}>
                        <Link to={"https://sereniuitvaartverzekering.be/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.funeral_insurance'}/>
                        </Link>
                        <br/>
                        <Link to={"https://www.sereni.be/waarom-sereni/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.why_sereni'}/>
                        </Link>
                        <br/>
                        <Link to={"https://www.sereni.be/uitvaart-regelen/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.arrange_a_funeral'}/>
                        </Link>
                    </Col>
                    <Col className={"mx-3"}>
                        <Link to={"https://www.sereni.be/inspiratie/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.inspiration'}/>
                        </Link>
                        <br/>
                        <Link to={"https://jobs.sereni.be/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.working_with_sereni'}/>
                        </Link>
                        <br/>
                        <Link to={"https://www.sereni.be/overlijden-24-7-bereikbaar/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.contact_us'}/>
                        </Link>
                    </Col>
                    <Col className={"mx-3"}>
                        <Link to={"https://www.sereni.be/zorgverlener-onderwijs/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.healthcare_and_education'}/>
                        </Link>
                        <br/>
                        <Link to={"https://www.samenonderweg.net/nl/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.events'}/>
                        </Link>
                    </Col>
                    <Col className={"mx-3"}>
                        <Link to={"https://www.sereni.be/privacy-beleid/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.privacy_policy'}/>
                        </Link>
                        <br/>
                        <Link to={"https://www.sereni.be/cookiebeleid/"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.cookies'}/>
                        </Link>
                        <br/>
                        <Link to={"mailto:nps@sereni.be"} target={"_blank"}>
                            <Trans t={t} i18nKey={'footer.complaints'}/>
                        </Link>
                    </Col>
                </Row>
                <hr/>
            </Container>
        </Fragment>
    );
}

export default Footer;
