    import {condolenceApi} from "../config/axiosConfigs";

export const CondolenceApi = {
    getAllByFuneralHome: async function (
        paging = {page: 1, amount: 9},
        filter = undefined,
        birthYear = undefined,
        deathYear = undefined,
        funeralHomeFilter = undefined,
        birthPlaces = [],
        placeOfDeath = []
    ) {
        const response = await condolenceApi.request({
            url: "/dossier/search",
            method: "POST",
            params: {page: paging.page},
            data: {
                "AMOUNT_PER_PAGE": paging.amount,
                "SEARCH_FILTER": filter,
                "YEAR_OF_BIRTH": birthYear,
                "YEAR_OF_DEATH": deathYear,
                "FUNERALHOME_ID": funeralHomeFilter,
                "PLACE_OF_BIRTH": birthPlaces,
                "PLACE_OF_DEATH": placeOfDeath

            }
        });
        return response.data;
    },

    getAllFuneralHomeBranch: async function () {
        const response = await condolenceApi.request({
            url: "/funeralhome",
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    },
    getDossierByAlias: async function (alias = String){
        const response  = await condolenceApi.request({
            url: `/dossier/${alias}`,
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    },

    createCondolence: async function(dossierNumber, name, email, phone, address, message, isPublic = false) {
        const response = await condolenceApi.request({
            url: '/condoleance/create',
            method: "PUT",
            data: {
                DOSSIER_NUMBER: dossierNumber,
                NAME: name,
                EMAIL: email,
                PHONE: phone,
                ADDRESS: address,
                MESSAGE: message,
                IS_PUBLIC: isPublic
            }
        });
        return response.data;
    },

    orderFlower: async function(dossierAlias, redirectUrl, flowerType, name, phone, invoice, email, company, vatNumber, street, postalCode, city, price, color, otherColor, messageCard, messageRibbon, language = "nl_NL") {
        const response = await condolenceApi.request({
            url:`/flowerorder/create`,
            method:"PUT",
            data: {
                DOSSIER_ALIAS: dossierAlias,
                FLOWER_IDENTIFIER: Number(flowerType),
                REDIRECTION_URL: redirectUrl,
                FLOWER_ORDER: {
                    NAME: name,
                    PHONE: phone,
                    EMAIL: email,
                    CARD_TEXT: messageCard,
                    MOURNING_LINT: !!messageRibbon ? messageRibbon : null,
                    AMOUNT: price,
                    COLOR: color,
                    OTHER_COLOR: otherColor,
                    INVOICE: invoice,
                    LANGUAGE: language,
                    VAT_NUMBER: vatNumber,
                    COMPANY: company,
                    STREET: street,
                    POSTAL_CODE: postalCode,
                    CITY: city
                }
            }
        });
        return response.data;
    },

    getFlowerPaymentStatus : async  function(hash) {
        const response = await condolenceApi.request({
            url: `/flowerorder/paymentstatus/${hash}`,
            method: "GET"
        });
        return response.data;
    },

    orderMourningCard: async function(dossierAlias, redirectUrl, cardType, name, phone, invoice, email, company, vatNumber, street, postalCode, city, message, language = "nl_NL") {
        const response = await condolenceApi.request({
            url:`/mourningcard/create`,
            method:"PUT",
            data: {
                DOSSIER_ALIAS: dossierAlias,
                MOURNING_CARD_IDENTIFIER: Number(cardType),
                REDIRECTION_URL: redirectUrl,
                MOURNING_CARD: {
                    NAME: name,
                    PHONE: phone,
                    EMAIL: email,
                    CARD_TEXT: message,
                    INVOICE: invoice,
                    LANGUAGE: language,
                    VAT_NUMBER: vatNumber,
                    COMPANY: company,
                    STREET: street,
                    POSTAL_CODE: postalCode,
                    CITY: city
                }
            }
        });
        return response.data;
    },

    getMourningCardPaymentStatus : async  function(hash) {
        const response = await condolenceApi.request({
            url: `/mourningcard/paymentstatus/${hash}`,
            method: "GET"
        });
        return response.data;
    },

    registerFuneral: async function(dossierNumber, name, email, phone, address, amount_of_people, participants, confirmation) {
        const response = await condolenceApi.request({
            url: '/funeral/create/subscription',
            method: 'POST',
            data: {
                DOSSIER_NUMBER: dossierNumber,
                NAME: name,
                EMAIL: email,
                PHONE: phone,
                ADDRESS: address,
                PARTICIPANT_AMOUNT: amount_of_people,
                PARTICIPANTS: participants
            }
        });
        return response.data;
    },
    registerCoffeeTable: async function(dossierNumber, name, email, phone, address, amount_of_people, participants, confirmation) {
        const response = await condolenceApi.request({
            url: '/coffeetable/create/subscription',
            method: 'POST',
            data: {
                DOSSIER_NUMBER: dossierNumber,
                NAME: name,
                EMAIL: email,
                PHONE: phone,
                ADDRESS: address,
                PARTICIPANT_AMOUNT: amount_of_people,
                PARTICIPANTS: participants
            }
        });
        return response.data;
    }
}