import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import "./FuneralFlower.scss";
import "./FuneralFlowerForm.scss";
import colors from "../../scss/theme-colors.module.scss";
import * as formik from "formik";
import * as yup from "yup";
import { generatePath, useParams } from "react-router-dom";
import { CondolenceApi } from "../../api/CondelenceApi";
import { PAGES } from "../../Pages";
import { toast } from "react-toastify";
import { InvoicingDetailsForm } from "../Shared/InvoicingDetailsForm";

const selectStyle = {
  control: (baseStyles) => ({
    ...baseStyles,
    fontSize: "13px",
    fontFamily: "DomaineText",
  }),
  option: (styles) => ({
    ...styles,
    color: "black",
    fontSize: "13px",
    fontFamily: "DomaineText",
  }),
};

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    neutral0: colors.formBgColor,
    neutral20: "black",
    neutral30: "black",
    primary: colors.secondary,
  },
});

const FuneralFlowerForm = ({ flowerPrice }) => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [step, setStep] = useState(1);
  const [executingCall, setExecutingCall] = useState(false);

  const colorOptions = [
    {
      label: t("flower_color.white_flower"),
      value: "white",
    },
    {
      label: t("flower_color.colored_flower"),
      value: "colored",
    },
    {
      label: t("flower_color.personal_choice"),
      value: "mychoice",
    },
  ];

  const [resultStep1, setResultStep1] = useState({
    price: flowerPrice.steps[0],
    color: colorOptions[0],
    otherColor: "",
    messageRibbon: "",
    messageCard: "",
  });

  const [resultStep2, setResultStep2] = useState({
    name: "",
    phone: "",
    email: "",
    invoice: false,
    company: "",
    vatNumber: "",
    street: "",
    postalCode: "",
    city: "",
  });

  const { Formik } = formik;

  const schema = yup.object().shape(
    {
      price: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number().required(),
        })
        .required(),
      color: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string().required(),
        })
        .required(),
      otherColor: yup
        .string()
        .ensure()
        .when("color", {
          is: (color) => color?.value === "mychoice",
          then: () => yup.string().required(),
        }),
      messageRibbon: yup
        .string()
        .ensure()
        .when("messageCard", {
          is: (messageCard) => !!!messageCard,
          then: () => yup.string().required(),
        }),
      messageCard: yup
        .string()
        .ensure()
        .when("messageRibbon", {
          is: (messageRibbon) => !!!messageRibbon,
          then: () => yup.string().required(),
        }),
    },
    [["messageCard", "messageRibbon"]]
  );

  const schema2 = yup.object().shape({
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().required(),
    invoice: yup.bool().required(),
    company: yup.string(),
    vatNumber: yup.string(),
    street: yup.string(),
    postalCode: yup.string(),
    city: yup.string(),
  });

  useEffect(() => {
    setResultStep1((prev) => ({ ...prev, price: flowerPrice.steps[0] }));
  }, [flowerPrice.steps]);

  function submitHandlerStep1(props) {
    setResultStep1(props);
    setStep(2);
  }

  function getRedirectUrl() {
    return `${window.location.origin}${generatePath(PAGES.CondolenceFlowers, {
      name: params.name,
    })}`;
  }

  function submitHandlerStep2(props) {
    setExecutingCall(true);
    CondolenceApi.orderFlower(
      params.name,
      getRedirectUrl(),
      flowerPrice.value,
      props.name,
      props.phone,
      props.invoice,
      props.email,
      props.company,
      props.vatNumber,
      props.street,
      props.postalCode,
      props.city,
      resultStep1.price.value,
      resultStep1.color.value,
      resultStep1.color.value === "mychoice"
        ? resultStep1.otherColor
        : undefined,
      resultStep1.messageCard,
      resultStep1.messageRibbon,
      i18n.language.toLowerCase() === "fr" ? "fr_FR" : "nl_NL"
    )
      .then((res) => {
        window.location.href = res.CHECKOUT_URL;
        setExecutingCall(false);
      })
      .catch((err) => {
        toast.error(t("error.invalid_customer"), { position: "top-center" });
        setExecutingCall(false);
      });
  }

  function stepBack(props) {
    setResultStep2(props);
    setStep(1);
  }

  function renderStep1() {
    return (
      <Formik
        validationSchema={schema}
        initialValues={resultStep1}
        onSubmit={submitHandlerStep1}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form
            className="flower-form bg-flower-form pt-4 mx-4 px-4 border-0"
            noValidate
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                <Trans t={t} i18nKey={"common.amount"} />
              </Form.Label>
              <Select
                id="price"
                key={flowerPrice ? flowerPrice.value : "default"}
                options={flowerPrice.steps}
                isDisabled={!(flowerPrice.steps.length > 0)}
                styles={selectStyle}
                theme={selectTheme}
                value={values.price}
                defaultValue={() => {
                  // setFieldValue("price", flowerPrice.steps[0]);
                  return flowerPrice.steps[0];
                }}
                onChange={(option) => setFieldValue("price", option)}
                isInvalid={!!errors.price?.value && touched.price}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-padding" controlId="formBasicName">
              <Form.Label>
                <Trans t={t} i18nKey={"common.color"} />
              </Form.Label>
              <Select
                options={colorOptions}
                id="color"
                styles={selectStyle}
                theme={selectTheme}
                value={values.color}
                onChange={(option) => setFieldValue("color", option)}
                isInvalid={!!errors.color?.value && touched.color}
              />
            </Form.Group>
            {values?.color?.value === "mychoice" && (
              <Form.Group className="mb-3 text-padding">
                <Form.Label>
                  <Trans t={t} i18nKey={"common.custom_color"} />
                </Form.Label>
                <Form.Control
                  as={"input"}
                  name="otherColor"
                  value={values.otherColor}
                  onChange={handleChange}
                  isInvalid={!!errors.otherColor && touched.otherColor}
                />
              </Form.Group>
            )}

            <Form.Group
              className="mb-3 mt-10 text-padding"
              controlId="formBasicName"
            >
              <Form.Label>
                <Trans t={t} i18nKey={"funeral_flower.text_of_ribbon"} />
                (+8,9 euro)
              </Form.Label>
              <br />
              <Form.Control
                as="textarea"
                rows={3}
                name="messageRibbon"
                value={values.messageRibbon}
                onChange={handleChange}
                isInvalid={!!errors.messageRibbon && touched.messageRibbon}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 mt-10 text-padding"
              controlId="formBasicName"
            >
              <Form.Label>
                <Trans t={t} i18nKey={"funeral_flower.text_card"} />
              </Form.Label>
              <br />
              <Form.Control
                as="textarea"
                rows={3}
                name="messageCard"
                value={values.messageCard}
                onChange={handleChange}
                isInvalid={!!errors.messageCard && touched.messageCard}
              />
            </Form.Group>

            <Button
              className="mb-4 mt-3 btn-condoleren"
              variant="secondary"
              type="submit"
            >
              <Trans t={t} i18nKey={"common.complete_payment"} />
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  if (resultStep1.price === undefined) {
    return <Fragment />;
  }

  return (
    <div className={"bg-flower-form flower-form-wrapper"}>
      {step === 1 && renderStep1()}
      {step === 2 && (
        <InvoicingDetailsForm
          schema={schema2}
          resultStep={resultStep2}
          submitHandlerStep={submitHandlerStep2}
          stepBack={stepBack}
          executingCall={executingCall}
        />
      )}
    </div>
  );
};
export default FuneralFlowerForm;
