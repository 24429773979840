import React from "react";
import {Show} from "../Util/Show";
import {DateHelper} from "../../helper/DateHelper";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {TranslationHelper} from "../../helper/TranslationHelper";


export function Farewell({funeralData, funeralLocationData, className}) {
    const {t, i18n} = useTranslation();

        // Get the translated strings
        const translatedAt = t('date.at');
        const translatedHour = t('date.hour');
    
        // Construct the format string dynamically
        const formatString = `dddd D MMMM YYYY [${translatedAt}] HH:mm [${translatedHour}]`;    

    return (
        <div className={className}>
            <h5><Trans t={t} i18nKey={'farewell.farewell'}/></h5>
            <Show>
                <Show.When isTrue={funeralData?.PUBLICCHARACTER === '1' || funeralData?.PUBLICCHARACTER === true}>
                    <div>
                        <p style={{fontSize: "13px"}}>
                            {DateHelper.formatDateWithLocale(funeralData.FUNERALDATETIME, formatString, i18n.language)}<br/>
                            {TranslationHelper.getValueBasedOnLocale(funeralLocationData, i18n)}<br/>
                            {funeralLocationData.STREETANDHOUSENUMBER}<br/>
                            {funeralLocationData.POSTALCODE} {funeralLocationData.CITY}<br/>
                            <Link target={"_blank"} to={encodeURI(`https://www.google.com/maps/search/?api=1&query=${funeralLocationData.STREETANDHOUSENUMBER} ${funeralLocationData.POSTALCODE} ${funeralLocationData.CITY}`)}>
                                Route
                            </Link>
                        </p>
                    </div>
                </Show.When>
                <Show.Else>
                    <p style={{fontSize: "13px"}}>
                        <Trans t={t} i18nKey={'farewell.no_farewell_text'}/>
                    </p>
                </Show.Else>
            </Show>
        </div>
    )
}
