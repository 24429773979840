import {DateHelper} from "../../helper/DateHelper";
import React, {Fragment} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Accordion} from "react-bootstrap";
import './MourningVisits.scss'
import {TranslationHelper} from "../../helper/TranslationHelper";

export function MourningVisits({showFuneral, mourningVisit, mourningVisitLocation, ...className}) {
    const {t, i18n} = useTranslation();

    function getDateFormatValues(date) {
        return {
            date: DateHelper.formatDateWithLocale(date, "dddd D MMMM YYYY", i18n.language),
            time: DateHelper.formatDateWithLocale(date, "HH:mm", i18n.language)
        };
    }

    function mourningConditions() {
        let content = undefined;
        if (showFuneral === '1' && ((!!mourningVisit.MOURNINGCHAMBERDAYPERIODS && mourningVisit.MOURNINGCHAMBERDAYPERIODS.length > 0) ||
                (!!mourningVisit.APPOINTMENTDAYPERIODS && mourningVisit.APPOINTMENTDAYPERIODS.length > 0)) &&
            (mourningVisit.MOURNINGCHAMBERUSAGETYPE === '2' ||
                mourningVisit.MOURNINGCHAMBERUSAGETYPE === '4' ||
                mourningVisit.MOURNINGCHAMBERUSAGETYPE === '6')) {
            content = (
                <div>
                    <strong>
                        {mourningUsageTypeTwoAndSix()}
                        {mourningChamber()}
                    </strong>
                    <br/>
                    {mourningUsageTypeTwo()}
                    {mourningUsageTypeFour()}
                    {mourningUsageTypeSix()}
                    {mourningVisitLocationByDateAndType()}
                </div>
            );
        }

        if (showFuneral === '1' && !!mourningVisit.AULAVISITDATE && mourningVisit.AULAVISITTYPE > '1') {
            content = (
                <>
                {content}
                <p>
                    {mourningVisitAULAVISITDATE_and_TYPE()}
                    <br/>
                    <Trans t={t} i18nKey={"date.datetime_format_at_hour"}
                           values={getDateFormatValues(mourningVisit.AULAVISITDATE)}/>
                    <br/>
                    {TranslationHelper.getValueBasedOnLocale(mourningVisitLocation, i18n)}<br/>
                    {mourningVisitLocation.STREETANDHOUSENUMBER}<br/>
                    {mourningVisitLocation.POSTALCODE} {mourningVisitLocation.CITY}
                </p>
                </>
            );
        }

        if (content === undefined) {
            return (<Fragment/>)
        }

        return (
            <Fragment>
                <Accordion {...className} bsPrefix={"mourning-accordion"}>
                    <Accordion.Item eventKey="0" style={{
                        borderWidth: '0 0 1px 0',
                        borderRadius: "0 !important",
                    }}>
                        <Accordion.Header>
                            <h5>
                                <Trans t={t} i18nKey={'common.funeral_visit'}/>
                            </h5>
                        </Accordion.Header>
                        <Accordion.Body style={{fontSize: '13px'}}>
                            {content}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Fragment>
        );
    }

    function mourningUsageTypeTwoAndSix() {
        return ((mourningVisit.MOURNINGCHAMBERUSAGETYPE === '2' || mourningVisit.MOURNINGCHAMBERUSAGETYPE === '6') ?
            <strong><Trans t={t} i18nKey={"common.funeral_visit_with_appointment"}/></strong> :
            <strong> <Trans t={t} i18nKey={"common.funeral_visit_during_openingHours"}/></strong>)
    }

    function mourningChamber() {
        return (
            !!mourningVisit.MOURNINGCHAMBERNAME ? <strong>
                <br/>
                `<Trans t={t} i18nKey={"common.funeral_room"}/> : {mourningVisit.MOURNINGCHAMBERNAME}`
            </strong> : ""
        )
    }

    function mourningUsageTypeTwo() {
        if (mourningVisit.MOURNINGCHAMBERUSAGETYPE === '2') {
            const validElements = mourningVisit.MOURNINGCHAMBERDAYPERIODS.map((period, index) => {
                const visitDate = DateHelper.toDate(period.DATE);
                const todayStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                if ((period.CLOSED == null || period.CLOSED === '0') && (visitDate >= todayStart)) {
                    return (
                        <p key={index}>
                            {DateHelper.formatDateWithLocale(period.DATE, "dddd D MMMM YYYY", i18n.language)}<br/>
                            <Trans t={t} i18nKey={"date.time_format_from_until"} values={{timeStart: DateHelper.formatDate(period.FROM, "HH:mm"), timeEnd: DateHelper.formatDate(period.TO, "HH.mm")}}/>
                        </p>
                    );
                }
                return null;
            }).filter(element => element !== null);
    
            // If there are no valid elements, return a default message
            if (validElements.length === 0) {
                return <p><Trans t={t} i18nKey={"common.no_valid_periods"}/></p>;
            }
    
            // Return the valid elements
            return validElements;
        }
    }

    function mourningUsageTypeFour() {
        if (mourningVisit.MOURNINGCHAMBERUSAGETYPE === '4') {
            const validElements = mourningVisit.MOURNINGCHAMBERDAYPERIODS.map((period, index) => {
                const visitDate = DateHelper.toDate(period.DATE);
                const todayStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                if ((period.CLOSED == null || period.CLOSED === '0') && (visitDate >= todayStart)) {
                    return (
                            <p key={index}>
                                {DateHelper.formatDateWithLocale(period.DATE, "dddd D MMMM YYYY", i18n.language)}<br/>
                                <Trans t={t} i18nKey={"date.time_format_from_until"} values={{timeStart: DateHelper.formatDate(period.FROM, "HH:mm"), timeEnd: DateHelper.formatDate(period.TO, "HH.mm")}}/>
                                <br/>
                            </p>
                    )
                }
                if (!!period.OVERRIDEOPENINGHOURS &&
                    period.OVERRIDEOPENINGHOURS === '1' &&
                    period.AFTERNOONFROM !== null &&
                    period.AFTERNOONTO !== null) {
                    return (
                        <p key={index}>
                            <Trans t={t} i18nKey={"date.time_format_from_until"} values={{timeStart: DateHelper.formatDate(period.FROM, "HH:mm"), timeEnd: DateHelper.formatDate(period.TO, "HH.mm")}}/>
                            <br/>
                            <br/>
                        </p>
                    );

                }
                return null
            }).filter(element => element !== null);

            // If there are no valid elements, return a default message
            if (validElements.length === 0) {
                return <p><Trans t={t} i18nKey={"common.no_valid_periods"}/></p>;
            }
    
            // Return the valid elements
            return validElements;
        }
    }

    function mourningUsageTypeSix() {
        if (mourningVisit.MOURNINGCHAMBERUSAGETYPE === '6') {
            return mourningVisit.APPOINTMENTDAYPERIODS.map((appointment, index) => {
                return (
                    <p key={index}>
                        {DateHelper.formatDateWithLocale(appointment.DATE, "dddd D MMMM YYYY", i18n.language)}<br/>
                        <Trans t={t} i18nKey={"date.time_format_from_until"} values={{timeStart: DateHelper.formatDate(appointment.FROM, "HH:mm"), timeEnd: DateHelper.formatDate(appointment.TO, "HH.mm")}}/>
                        <br/>
                        <br/>
                    </p>
                )
            });
        }
    }

    function mourningVisitLocationByDateAndType() {
        if (!!mourningVisitLocation && !(!!mourningVisit.AULAVISITDATE && mourningVisit.AULAVISITTYPE > '1')) {
            return (
                <p>
                    {TranslationHelper.getValueBasedOnLocale(mourningVisitLocation, i18n)}<br/>
                    {mourningVisitLocation.STREETANDHOUSENUMBER} <br/>
                    {mourningVisitLocation.POSTALCODE} {mourningVisitLocation.CITY}
                </p>
            )
        }
    }

    function mourningVisitAULAVISITDATE_and_TYPE() {
        if (mourningVisit.MOURNINGCHAMBERUSAGETYPE === '2') {
            return (<strong><Trans t={t} i18nKey={"common.funeral_visit_without_appointment"}/></strong>)
        } else {
            if ((mourningVisit.MOURNINGCHAMBERDAYPERIODS.length > 0 &&
                    mourningVisit.MOURNINGCHAMBERUSAGETYPE === '4') ||
                (mourningVisit.APPOINTMENTDAYPERIODS.length > 0
                    && mourningVisit.MOURNINGCHAMBERUSAGETYPE === '6')) {
                return (<strong> <Trans t={t} i18nKey={"common.and_on"}/></strong>)
            } else {
                return (<strong><Trans t={t} i18nKey={"common.funeral_visit"}/></strong>)
            }
        }
    }

    return (
        <Fragment>{mourningConditions()}</Fragment>
    )
}
