import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, {Fragment, useEffect, useState} from "react";
import {generatePath, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import '../../App.scss'
import './ParticipationCardInfo.scss'
import {CondolenceApi} from "../../api/CondelenceApi";
import {Spinner} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import {BackButton} from "../BackButton/BackButton";
import {PAGES} from "../../Pages";
import {ProductCarousel} from "../ProductCarousel/ProductCarousel";
import {useCards} from "./useCards";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import { Show } from "../Util/Show";

export function ParticipationCardInfo() {
    const {t, i18n} = useTranslation();
    const [dossierData, setDossierData] = useState();
    const [loading, setLoading] = useState(false);
    const {name} = useParams();
    const navigate = useNavigate();
    const {cardsMap} = useCards();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        fetchDossierByAlias(name).then((res) => {
            setDossierData(res);
            setLoading(false);
        })
            .catch(_ => toast.error(t('error.general'),{position: "top-center"}));
    }, [name, t]);

    useEffect(() => {
        if (searchParams.has('hash')) {
            const hash = searchParams.get("hash");
            searchParams.delete('hash');
            CondolenceApi.getMourningCardPaymentStatus(hash)
                .then((res) => {
                    if (res.status === 'paid') {
                        toast.success(t('participation_card.order_succes'),{position: "top-center"});
                    } else {
                        toast.warn(t('participation_card.order_error'),{position: "top-center"});
                    }
                })
                .catch(_ => toast.error(t('error.general'),{position: "top-center"}));
        }
    }, [searchParams, t]);

    async function fetchDossierByAlias(alias = String) {
        return CondolenceApi.getDossierByAlias(alias);
    }

    function cardClicked(item, cardImages) {
        navigate(generatePath(PAGES.ParticipationCardShop, {name}), {state: {cardType: item, cardImages}})
    }

    return (
        <Container>
            <Row>
                <BackButton path={generatePath(PAGES.PersonDetail, {name: name})}
                            textComponent={<Trans t={t} i18nKey={"common.back"}/>}/>
            </Row>

            {loading === true && (
                <Row className={"justify-content-center mb-4"}>
                    <Spinner animation="border"/>
                </Row>
            )}

            {!!dossierData && (
                <Fragment>
                    <Row className="mx-2 justify-content-md-center mb-5">
                        <Col md={8} className={"align-content-end"}>
                            <h1 className='participation-card-header-title'>
                                <Trans t={t} i18nKey={"participation_card.title"}/>
                            </h1>
                        </Col>
                    </Row>
                    <Row className="mx-2 justify-content-md-center mb-2">
                        <Col md={8} className={"align-content-end"}>
                            <p className='participation-card-header-title'>
                                <Trans t={t} i18nKey={"participation_card.info"}/>
                            </p>
                        </Col>
                    </Row>
                    <Row className="mx-2 justify-content-md-center mb-4">
                        <Col md={3}>
                            <p className="m-2 p-3 px-4 border border-dark">
                                <Trans t={t} i18nKey={"participation_card.order_info"}/>
                            </p>
                        </Col>
                        <Col md={5} className="align-self-center mb-2 pb-2">
                            <h1>{dossierData.deceased_full_name}</h1>
                            <Show>
                                <Show.When isTrue={dossierData.dossier_number !== "38984"}>
                                    <span className="m-1 h5">{DateHelper.formatDateWithLocale(dossierData.deceased.BIRTHDATE, "DD MMMM YYYY", i18n.language)}
                                    </span>
                                    &nbsp;|&nbsp;
                                    <span className="m-1 h5">{DateHelper.formatDateWithLocale(dossierData.date_of_passing, "DD MMMM YYYY", i18n.language)}
                                    </span>
                                </Show.When>
                            </Show>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {[...cardsMap.keys()].map((item) => (
                            <Col key={item} md={4} className={"my-3"}>
                                <ProductCarousel onClick={() => {cardClicked(item, cardsMap.get(item))}}
                                    images={cardsMap.get(item)}
                                    price={"9.00"}
                                />
                            </Col>
                        ))}
                    </Row>
                </Fragment>
            )}

        </Container>
    )
}
