import {Link} from "react-router-dom";
import React from "react";
import Arrow from "../../assets/images/pijlNav.svg";
import './BackButton.scss'

export function BackButton({path, textComponent}) {
    return (
        <Link to={path} className="float-start my-2 translation w-auto">
            <img src={Arrow} className={"back-arrow me-1"} alt={"Arrow"}/>
            {textComponent}
        </Link>
    )
}
