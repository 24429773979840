import {DOTS, usePagination} from "./usePagination";
import classnames from 'classnames';
import "./Pagination.scss"
import {Show} from "../Util/Show";
import {Each} from "../Util/Each";
import React from "react";
import Arrow from "../../assets/images/pijlNav.svg";

export function Pagination(
    {
        onPageChange,
        totalCount,
        siblingCount = 2,
        currentPage,
        pageSize,
        className
    }
) {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (totalCount === 0) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    let firstPage = paginationRange[0];

    function dotsOrPage(pageNumber) {
        if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
        }
        return (
            <li
                className={classnames('pagination-item', {
                    selected: pageNumber === currentPage
                })}
                onClick={() => onPageChange(pageNumber)}
            >
                {pageNumber}
            </li>
        );
    }

    return (
        <ul
            className={classnames('pagination-container', {[className]: className})}
        >

            {/* Left navigation arrow */}

            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <Show>
                    <Show.When isTrue={currentPage !== firstPage}>
                        <img src={Arrow} style={{width: "20px", rotate: "180deg"}} alt={"Arrow"}/>
                    </Show.When>
                </Show>
            </li>

            <Each of={paginationRange} render={dotsOrPage} />
            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <Show>
                    <Show.When isTrue={currentPage !== lastPage}>
                        {/*<FontAwesomeIcon icon={faArrowRight} onClick={onPrevious}/>*/}
                        <img src={Arrow} style={{width: "20px"}} alt={"Arrow"}/>
                    </Show.When>
                </Show>
            </li>
            {/*  Right Navigation arrow */}

        </ul>
    );
}
