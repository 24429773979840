import {Trans, useTranslation} from "react-i18next";
import * as formik from "formik";
import * as yup from "yup";
import {Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import './InvitationModal.scss'


export const defaultInvitationModalData = {show: false};

export function InvitationModal({show = true, closeModal, title, externalSubmitHandler = () => {}}) {
    const {t} = useTranslation();
    const {Formik} = formik;

    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string(),
        phone: yup.string().required(),
        address: yup.string(),
        amount_of_people: yup.number().required(),
        who: yup.string().required(),
        confirmation: yup.bool().oneOf([true], 'Field must be checked').required()
    });

    const initialValue = {
        name: "",
        email: "",
        phone: "",
        address: "",
        amount_of_people: "",
        who: "",
        confirmation: false
    }

    function submitHandler({name, email, phone, address, amount_of_people, who, confirmation}) {
        externalSubmitHandler(name, email, phone, address, amount_of_people, who.split('\n'), confirmation);
    }

    return (
        <Modal className={"invitationModalNoBorderRadius"} show={show} onHide={closeModal}>
            <Modal.Header className='bg-warning-light' closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-0"}>
                <Formik validationSchema={schema} initialValues={initialValue} onSubmit={submitHandler}>
                    {
                        ({handleSubmit, handleChange, values, touched, errors}) => (
                            <Form className='bg-condolence-form pt-4 px-4 border-0' noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-4" controlId="formBasicName">
                                    {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.name'}/></Form.Label>*/}
                                    <Form.Control type="text"
                                                  placeholder={t("common.name")}
                                                  name="name"
                                                  value={values.name}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.name && touched.name}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.email'}/></Form.Label>*/}
                                    <Form.Control type="email"
                                                  placeholder={t("common.email")}
                                                  name="email"
                                                  value={values.email}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.email && touched.email}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicTelephone">
                                    {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.telephone'}/></Form.Label>*/}
                                    <Form.Control type="text"
                                                  placeholder={t("common.telephone")}
                                                  name="phone"
                                                  value={values.phone}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.phone && touched.phone}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicAddress">
                                    {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.address'}/></Form.Label>*/}
                                    <Form.Control type="text"
                                            placeholder={t("common.address")}
                                            name="address"
                                            value={values.address}
                                            onChange={handleChange}
                                            isInvalid={!!errors.address && touched.address}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="formBasicAmount">
                                    {/*<Form.Label className={"medium-text"}><Trans t={t} i18nKey={'common.amount_of_people'}/></Form.Label>*/}
                                    <Form.Control type="number"
                                            placeholder={t("common.amount_of_people")}
                                            name="amount_of_people"
                                            value={values.amount_of_people}
                                            onChange={handleChange}
                                            isInvalid={!!errors.amount_of_people && touched.amount_of_people}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-5" controlId="formBasic">
                                    <Form.Control rows={4} as="textarea"
                                                  placeholder={t("common.who_invited")}
                                                  name="who"
                                                  value={values.who}
                                                  onChange={handleChange}
                                                  isInvalid={!!errors.who && touched.who}
                                    />
                                    <small>{t("common.participant_on_new_line")}</small>
                                </Form.Group>
                                <Form.Group className={"mb-4"} controlId="formBasicInvitationCheckbox">
                                    <Form.Check type="checkbox"
                                                label={<Trans t={t} i18nKey={'condolence.privacy_consent'}/>}
                                                name="confirmation"
                                                value={values.confirmation}
                                                onChange={handleChange}
                                                isInvalid={!!errors.confirmation && touched.confirmation}
                                    />
                                </Form.Group>
                                <Button className='mb-4 mt-3 btn-condoleren' variant="secondary" type="submit">
                                    {title}
                                </Button>
                            </Form>
                        )
                    }
                </Formik>
            </Modal.Body>
        </Modal>
    )
}