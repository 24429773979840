import * as formik from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { Trans, useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import "./ParticipationCardForm.scss";
import { generatePath, useParams } from "react-router-dom";
import React, { useState } from "react";
import { CondolenceApi } from "../../../api/CondelenceApi";
import { PAGES } from "../../../Pages";
import { toast } from "react-toastify";
import { InvoicingDetailsForm } from "../../Shared/InvoicingDetailsForm";

export function ParticipationCardForm({ selectedCard }) {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [step, setStep] = useState(1);
  const [executingCall, setExecutingCall] = useState(false);
  const [resultStep1, setResultStep1] = useState({
    message: "",
  });
  const [resultStep2, setResultStep2] = useState({
    name: "",
    phone: "",
    email: "",
    invoice: false,
    company: "",
    vatNumber: "",
    street: "",
    postalCode: "",
    city: "",
  });
  const { Formik } = formik;

  const schema = yup.object().shape({
    message: yup.string(),
  });

  const schema2 = yup.object().shape({
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().required(),
    invoice: yup.bool().required(),
    company: yup.string(),
    vatNumber: yup.string(),
    street: yup.string(),
    postalCode: yup.string(),
    city: yup.string(),
  });

  function submitHandler(props) {
    setStep(2);
    setResultStep1(props);
  }

  function getRedirectUrl() {
    return `${window.location.origin}${generatePath(PAGES.ParticipationCard, {
      name: params.name,
    })}`;
  }

  function submitHandlerStep2(props) {
    setExecutingCall(true);
    CondolenceApi.orderMourningCard(
      params.name,
      getRedirectUrl(),
      selectedCard,
      props.name,
      props.phone,
      props.invoice,
      props.email,
      props.company,
      props.vatNumber,
      props.street,
      props.postalCode,
      props.city,
      resultStep1.message,
      i18n.language.toLowerCase() === "fr" ? "fr_FR" : "nl_NL"
    )
      .then((res) => {
        window.location.href = res.CHECKOUT_URL;
        setExecutingCall(false);
      })
      .catch((err) => {
        toast.error(t("error.invalid_customer"), { position: "top-center" });
        setExecutingCall(false);
      });
  }

  function stepBack(props) {
    setResultStep2(props);
    setStep(1);
  }

  function renderStep1() {
    return (
      <Formik
        validationSchema={schema}
        initialValues={resultStep1}
        onSubmit={submitHandler}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form
            className="bg-participation-card-form participation-card-form pt-4 px-4 border-0"
            noValidate
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-5">
              <Form.Label>
                <Trans t={t} i18nKey={"common.message"} />
              </Form.Label>
              <Form.Control
                rows={6}
                as="textarea"
                name="message"
                value={values.message}
                onChange={handleChange}
                isInvalid={!!errors.message && touched.message}
              />
            </Form.Group>
            <Button
              className="mb-4 mt-3 btn-condoleren"
              variant="secondary"
              type="submit"
            >
              <Trans t={t} i18nKey={"common.complete_payment"} />
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <div
      className={"bg-participation-card-form participation-card-form-wrapper"}
    >
      {step === 1 && renderStep1()}
      {step === 2 && (
        <InvoicingDetailsForm
          schema={schema2}
          resultStep={resultStep2}
          submitHandlerStep={submitHandlerStep2}
          stepBack={stepBack}
          executingCall={executingCall}
        />
      )}
    </div>
  );
}
