import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import React from "react";
import classnames from "classnames";
import {TranslationHelper} from "../../helper/TranslationHelper";
import {useWindowDimensions} from "../../helper/useWindowDimensions";

export function FuneralHome({funeralHomeData, funeralEntrepreneur, className = undefined}) {
    const {t, i18n} = useTranslation();
    const windowDimensions = useWindowDimensions();

    return (
        <div className={classnames(className, "funeral_home")}>
            <Link className={"anchor-no-decoration"}
                  to={TranslationHelper.getWebsiteBasedOnLocale(funeralHomeData, i18n)}>
                <h5><Trans t={t} i18nKey={"funeral_home.funeral_home_name"}
                           values={{funeral_home_name: funeralHomeData.NAME}}/></h5>
            </Link>
            <p style={{fontSize: "13px"}}>
                {!windowDimensions.isSmall && <Trans t={t}
                                                     i18nKey={"funeral_home.planning"}
                                                     components={{
                                                         home_url: <Link className={"anchor-no-decoration"}
                                                                         to={TranslationHelper.getWebsiteBasedOnLocale(funeralHomeData, i18n)}>{funeralHomeData.NAME}</Link>
                                                     }}
                                                     values={{
                                                         funeral_url: TranslationHelper.getWebsiteBasedOnLocale(funeralHomeData, i18n),
                                                         person_name: TranslationHelper.getValueBasedOnLocale(funeralEntrepreneur, i18n),
                                                         funeral_home_name: funeralHomeData.NAME,
                                                         funeral_home_phone: funeralHomeData.PHONE
                                                     }}
                />}
                {windowDimensions.isSmall && <Trans t={t}
                                                    i18nKey={"funeral_home.planning_phone"}
                                                    components={{
                                                        home_url: <Link className={"anchor-no-decoration"}
                                                                        to={TranslationHelper.getWebsiteBasedOnLocale(funeralHomeData, i18n)}>{funeralHomeData.NAME}</Link>,
                                                        phone_url: <Link
                                                            to={`tel:${funeralHomeData.PHONE}`}>funeralHomeData.PHONE</Link>
                                                    }}
                                                    values={{
                                                        funeral_url: TranslationHelper.getWebsiteBasedOnLocale(funeralHomeData, i18n),
                                                        person_name: TranslationHelper.getValueBasedOnLocale(funeralEntrepreneur, i18n),
                                                        funeral_home_name: funeralHomeData.NAME,
                                                        funeral_home_phone: funeralHomeData.PHONE
                                                    }}
                />}
                <br/>
                {/* 
                    rel="noopener noreferrer": 
                    Provides security by preventing the new page from being able to access the window object of the opener page. 
                    This is a best practice when using target="_blank" 
                */}
                <Link
                    target={"_blank"}
                    rel="noopener noreferrer"
                    to={encodeURI(`https://www.google.com/maps/search/?api=1&query=${funeralHomeData.STREETANDHOUSENUMBER} ${funeralHomeData.POSTALCODE} ${funeralHomeData.CITY}`)}>
                    Route
                </Link>
            </p>
        </div>
    );
}
