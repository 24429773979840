import {useMemo} from "react";
import {useParams, generatePath} from "react-router-dom";
import {PAGES} from "../../Pages";
import {useTranslation} from "react-i18next";

export const GRID_KEYS = {
    CONDOLENCE: "CONDOLENCE",
    FLOWERS: "FLOWERS",
    MOURN_COMMUNICATION: "MOURN_COMMUNICATION",
    MOURN_LIST: "MOURN_LIST",
    MOURNING_CARD: "MOURNING_CARD",
    CONSOLATION_MEAL: "CONSOLATION_MEAL",
    COFFEE_TABLE: "COFFEE_TABLE",
    FUNERAL_INVITATION: "FUNERAL_INVITATION",
    REMINDER_CARD: "REMINDER_CARD",
    SHARE_IMG: "SHARE_IMG",
    FINANCIAL_SUPP: "FINANCIAL_SUPP"
}

const COFFEE_TABLE_URLS = process.env.REACT_APP_COFFEE_TABLE_URLS.split(',');
const FUNERAL_INVITATION_URLS = process.env.REACT_APP_FUNERAL_INVITATION_URLS.split(',');
const BASE_URL = process.env.REACT_APP_BASE_URL

export function isSpecialUrl() {
    return isValidUrl(COFFEE_TABLE_URLS) || isValidUrl(FUNERAL_INVITATION_URLS)
}

function isValidUrl(urls) {
    // check if the list of urls contains the current hostname
    return urls.some(url => window.location.hostname.includes(url));
}

export const useServiceGridItems = (element, coffeeTableClickHandler, funeralInvitationClickHandler) => {
    const {name} = useParams();
    const {t} = useTranslation();

    return useMemo(() => {
        let service = {elementsExceptCondolence: [], elementCount: 0};
        const isCoffeeUrl = isValidUrl(COFFEE_TABLE_URLS)
        const isInvitationUrl = isValidUrl(FUNERAL_INVITATION_URLS)
        if (!!element) {
            if (!(isCoffeeUrl || isInvitationUrl)) {
                service.condolence = {
                    title: t('common.condolences'),
                    subText: t("person_detail_card_text.condolence"),
                    subTextDisabled: t("person_detail_card_text.condolence_disabled"),
                    url: generatePath(PAGES.CondolenceForm, {name}),
                    key: GRID_KEYS,
                    active: element.show_condoleances === "1",
                    bg: 'card-condolences'
                };
                addServiceIfActive(service, service.condolence);

                service.flower = {
                    title: t('common.condolences_flower'),
                    subText: t("person_detail_card_text.flower"),
                    url: generatePath(PAGES.CondolenceFlowers, {name}),
                    key: GRID_KEYS.FLOWERS,
                    bg: 'card-flowers',
                    ...selectCorrectDisabledText(
                        element.allow_flowers === 1,
                        element.show_flowers === true,
                        "person_detail_card_text.flower_disabled",
                        "person_detail_card_text.flower_disabled_time_constraint",
                        t
                    )
                };
                addServiceIfActive(service, service.flower);

                service.mourning_card = {
                    title: t('common.consolation_card'),
                    subText: t('person_detail_card_text.mourning_card'),
                    url: generatePath(PAGES.ParticipationCard, {name}),
                    urlIsExternal: false,
                    key: GRID_KEYS.MOURNING_CARD,
                    bg: 'card-condolences-card',
                    ...selectCorrectDisabledText(
                        +element.funeral.SHOW_MOURNING_CARDS === 1,
                        element.show_mourning_card,
                        "person_detail_card_text.mourning_card_disabled",
                        "person_detail_card_text.mourning_card_disabled_time_constraint",
                        t
                    )
                };
                addServiceIfActive(service, service.mourning_card);

                service.hngry = {
                    title: t('common.consolation_meals'),
                    subText: t("person_detail_card_text.consolation_meal"),
                    url: element.consolation_meals_url,
                    urlIsExternal: true,
                    key: GRID_KEYS.CONSOLATION_MEAL,
                    bg: 'card-meal',
                    ...selectCorrectDisabledText(
                        +element.funeralhome.SHOW_CONSOLATION_MEALS === 1 && +element.funeral.SHOW_CONSOLATION_MEALS === 1,
                        element.show_consolation_meals,
                        "person_detail_card_text.consolation_meal_disabled",
                        "person_detail_card_text.consolation_meal_disabled_time_constraint",
                        t
                    )
                };
                addServiceIfActive(service, service.hngry);

                service.financial_support = {
                    title: t('common.financial_support'),
                    subText: t("person_detail_card_text.financial_support"),
                    subTextDisabled: t("person_detail_card_text.financial_support_disabled"),
                    key: GRID_KEYS.FINANCIAL_SUPP,
                    active: false,
                    bg: 'card-financial-support',
                };
                addServiceIfActive(service, service.financial_support);

                service.mourning_communication = {
                    title: t('common.mourning_communication'),
                    subText: t("person_detail_card_text.mourning_communication"),
                    subTextDisabled: t("person_detail_card_text.mourning_communication_disabled"),
                    url: element.pdf,
                    urlIsExternal: true,
                    key: GRID_KEYS.MOURN_COMMUNICATION,
                    active: element.has_pdf,
                    bg: 'card-communication',
                };
                addServiceIfActive(service, service.mourning_communication);

                service.reminder = {
                    title: t('common.reminder_communication'),
                    subText: t('person_detail_card_text.reminder_communication'),
                    subTextDisabled: t('person_detail_card_text.reminder_communication_disabled'),
                    url: element.reminder_pdf,
                    urlIsExternal: true,
                    key: GRID_KEYS.REMINDER_CARD,
                    active: +element.show_reminder_pdf === 1,
                    bg: 'card-reminder'
                };
                addServiceIfActive(service, service.reminder);

                service.mourning_list = {
                    // title: t('common.mounring_list'),
                    // subText: t("person_detail_card_text.mounring_list"),
                    // subTextDisabled: t("person_detail_card_text.mounring_list_disabled"),
                    title: '',
                    subText: '',
                    subTextDisabled: '',
                    key: GRID_KEYS.MOURN_LIST,
                    active: false,
                    bg: 'card-mourning-list',
                };
                addServiceIfActive(service, service.mourning_list);

                service.share_image = {
                    // title: t('common.share_image'),
                    // subText: t("person_detail_card_text.share_image"),
                    // subTextDisabled: t("person_detail_card_text.share_image_disabled"),
                    title: '',
                    subText: '',
                    subTextDisabled: '',
                    key: GRID_KEYS.SHARE_IMG,
                    active: false,
                    bg: 'card-share-image',
                };
                addServiceIfActive(service, service.share_image);
            } else {
                if (isCoffeeUrl) {
                    service.coffee_table = {
                        subText: t('person_detail_card_text.register_coffee_table'),
                        subTextDisabled: t('person_detail_card_text.register_coffee_table_disabled'),
                        key: GRID_KEYS.COFFEE_TABLE,
                        active: element.show_coffeetable,
                        bg: 'card-coffee_table',
                        onClickHandler: coffeeTableClickHandler
                    }
                    service.elementsExceptCondolence.push(service.coffee_table);
                    service.elementCount += 1;
                }
                if (isInvitationUrl) {
                    service.funeral_invitations = {
                        subText: t('person_detail_card_text.funeral_invitation'),
                        subTextDisabled: t('person_detail_card_text.funeral_invitation_disabled'),
                        active: element.show_funeral_invitations,
                        key: GRID_KEYS.FUNERAL_INVITATION,
                        bg: 'card-invitation',
                        onClickHandler: funeralInvitationClickHandler
                    };
                    service.elementsExceptCondolence.push(service.funeral_invitations);
                    service.elementCount += 1;
                }
                service.redirect = {
                    subText: t('person_detail_card_text.personal_page_redirect'),
                    active: true,
                    bg: 'card-redirect',
                    url: `${BASE_URL}${generatePath(PAGES.PersonDetail, {name})}`,
                    urlIsExternal: true,
                };
                service.elementsExceptCondolence.push(service.redirect);
                service.elementCount += 1;
            }
        }
        return service;
    }, [element, name, t, coffeeTableClickHandler, funeralInvitationClickHandler])
}

export function showFlowers(element) {
    return element.show_flowers === true
}

function selectCorrectDisabledText(allowCard, inTimeConstraint, normalDisableKey, timeConstraintDisableKey, t) {
    const key = allowCard === true ? (inTimeConstraint ? normalDisableKey : timeConstraintDisableKey) : normalDisableKey
    return {
        active: allowCard && inTimeConstraint,
        allow: allowCard === true,
        withTimeConstraint: true,
        subTextDisabled: t(key)
    }
}

function addServiceIfActive(service, item) {
    if (item.allow === true && item.withTimeConstraint !== undefined) {
        service.elementsExceptCondolence.push(item)
        service.elementCount += 1;
        return;
    }
    if (item.active) {
        service.elementsExceptCondolence.push(item);
        service.elementCount += 1;
    }
}
